
// detect touch devices by registering the first touch
window.addEventListener('touchstart', function onFirstTouch() {
    $('body').addClass('js-has-touch');
    window.removeEventListener('touchstart', onFirstTouch, false);
}, false);


// initialize navi-toggle
$('.js-main-navi__toggle').on('click', function() {
    $('.main-navi').toggleClass('main-navi--open');
    $('.js-main-navi__toggle').toggleClass('navi-toggle--open');
});




doTheGrid(); // runs on doc ready
$(window).resize(function() {
    doTheGrid();
});


function doTheGrid() {
    var w = $(window).width(),
        rowH = 160,
        maxRowH = '120%';

    if( w > 959 ) {
        rowH = 160;
        maxRowH = 160;
    }
    else if( w > 639 ) {
        rowH = 240;
        maxRowH = '130%';
    }


    $('.js-justified-grid').justifiedGallery({
        rowHeight: rowH,
        maxRowHeight: maxRowH,
        margins: 5,
        lastRow: 'justify'
    });
}


/*
$('.js-justified-grid').justifiedGallery({
    rowHeight: 200,
    maxRowHeight: '150%',
    margins: 5,
    lastRow: 'justify',
    /*
    sizeRangeSuffixes: {
        100 : '-q68', // used with images which are less than 100px on the longest side
        200 : '-q69',
        400 : '-q70'
        600 : ''
    }
});
    */

